import { defineNuxtRouteMiddleware } from "nuxt/app";
import { useQuery } from "@vue/apollo-composable";
export default defineNuxtRouteMiddleware(async (to, from) => {
  const { getToken, onLogout } = useApollo();

  const token = await getToken();
  const hasToken = !!token;

  if (!hasToken) {
    return navigateTo("/login");
  } else {
    const { onResult, onError } = await useQuery(whoAmI);

    onError((queryResult) => {
      console.log("Bye bye 👋🏻");
      onLogout();
      return navigateTo("/login");
    });
    onResult((queryResult) => {
      if (!queryResult.data.me === null) {
        onLogout();
        return navigateTo("/login");
      }
    });
  }
});

const whoAmI = gql`
  query me {
    me {
      id
    }
  }
`;
